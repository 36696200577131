import Vue from 'vue';
import App from '@/App.vue';

// Pinia setup
import { PiniaVuePlugin } from 'pinia';
import pinia from '@/plugins/pinia';
Vue.use(PiniaVuePlugin);

// Vue router
import Router from 'vue-router';
import router from '@/router/router';
Vue.use(Router);

import '@/styles/fontAwesome';

// vue-announcer
import VueAnnouncer from 'vue-announcer';
Vue.use(VueAnnouncer, {}, router);

// app version checker store
import useAppVersionStore from './stores/appVersion';

// v-calendar
import VCalendar from 'v-calendar';
Vue.use(VCalendar, {
	componentPrefix: 'vc', // Use <vc-calendar /> instead of <v-calendar />
});

// vee-validate
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { setupValidation } from '@/validationSetup';
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
setupValidation();

import * as Sentry from '@sentry/vue';
import * as Integrations from '@sentry/integrations';

if (process.env.NODE_ENV === 'production') {
	Sentry.init({
		dsn: 'https://a059ccf0c5ec4a0499187e2de69da67f@sentry.io/1424454',
		Vue,
		trackComponents: true,
		integrations: [
			new Integrations.ReportingObserver({
				types: ['crash', 'deprecation', 'intervention'],
			}),
			new Sentry.BrowserTracing({
				routingInstrumentation: Sentry.vueRouterInstrumentation(router),
				tracingOrigins: ['mysfdomain.com', /^\//],
			}),

			// Housekeeping: re-enable this once our error rate drops within quota limits
			// new Sentry.Replay(),
		],
		// Capture Replay for 10% of all sessions,
		// plus for 100% of sessions with an error
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
	});
	const appVersionStore = useAppVersionStore(pinia);
	appVersionStore.periodicallyCheckForNewVersion();
	// to use a store outside a component, we need to pass the pinia object in
	router.afterEach(() => {
		if (appVersionStore.foundNewVersion) {
			appVersionStore.updateApp();
		}
	});
}

import Peachy from '@/utils/MXEditor/Peachy';
Vue.use(Peachy);

Vue.config.productionTip = false;
Vue.config.keyCodes.plus = 61;
Vue.config.keyCodes.minus = 173;

import '@/styles/index.css';

new Vue({
	name: 'MySFDomainUI',

	router,
	pinia,
	render: h => h(App),
}).$mount('#app');
